import { getSnowplowEventPayload, logAnalyticsEvents } from 'Services/analytics/analytics';
import { ANALYTICS_CATEGORIES, ANALYTICS_PROVIDERS, SNOWPLOW_EVENTS } from 'Services/analytics/analytics.const';

const { VEHICLE_DETAILS } = ANALYTICS_CATEGORIES;

const { SNOWPLOW } = ANALYTICS_PROVIDERS;

const { CLICK } = SNOWPLOW_EVENTS;

export const triggerUserConvertedStaticMapToDynamicEvent = (): void => {
	logAnalyticsEvents([
		{
			eventPayload: getSnowplowEventPayload({
				category: VEHICLE_DETAILS,
				name: `dlr_user_converted_static_map_to_dynamic`,
				schemaName: CLICK.SCHEMA,
				version: CLICK.VERSION,
			}),
			provider: SNOWPLOW,
		},
	]);
};
